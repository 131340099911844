import React, { useState } from "react";
import { Box, Container } from "@mui/material";

import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

import { useTheme } from "apps/form/style/useTheme";

document.body.style.margin = 0;
export function CircularLoader(props) {
  const { style } = useTheme();
  const [primary] = useState(style.color ? style.colors.primary : "blue");
  const styles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: style.colors ? style.colors.body.bg : "white",
    height: "100vh",
    flexGrow: 1,
  };
  return (
    <div
      className="container-fluid"
      style={{ margin: 0, boxSizing: "border-box" }}
    >
      <Box sx={styles}>
        <Box sx={{ position: "relative" }}>
          <CircularProgress
            variant="determinate"
            sx={{
              color: (theme) =>
                theme.palette.grey[
                  theme.palette.mode === "light" ? "transparent" : 800
                ],
            }}
            size={40}
            thickness={4}
            {...props}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: (theme) =>
                theme.palette.mode === "light" ? primary : "#308fe8",
              animationDuration: "550ms",
              position: "absolute",
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
            size={40}
            thickness={4}
            {...props}
          />
        </Box>
      </Box>
    </div>
  );
}
