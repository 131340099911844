import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showWelcomeMessage: false,
};

export const welcomeSlice = createSlice({
  name: "welcome",
  initialState,
  reducers: {
    setShowWelcome: (state, action) => {
      state.showWelcomeMessage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setShowWelcome } = welcomeSlice.actions;

export default welcomeSlice.reducer;
