import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";

export default function FormWelcome({ children }) {
  const showWelcome = useSelector((state) => state.welcome.showWelcomeMessage);
  const location = useLocation();

  return showWelcome === true ? (
    <Navigate to="/form/welcome" replace state={{ path: location.pathname }} />
  ) : (
    children
  );
}
