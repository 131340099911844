import { createTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { store } from "store/store";
const Theme = createTheme({});
const state = store.getState();

export const useTheme = () => {
  const [style, setStyle] = useState({
    colors: {
      primary: "skyblue",
      header: {
        bg: "#f5f0e1",
        text: "#FFFFFF",
      },
      body: {
        bg: "#322e2f",
        text: "white",
      },
    },
  });
  const theme = useSelector((state) => state.init.theme);
  useEffect(() => {
    setStyle(theme);
  }, [theme]);

  return { style, setStyle };
};

export default Theme;
