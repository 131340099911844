import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import allRoutes from "routes/routes";
import RequireAuth from "routes/RequireAuth";
import FormWelcomeRoute from "routes/FormWelcome";
import AppRoutes from "routes/AppRoutes";
import { useDispatch, useSelector } from "react-redux";
import { fetchInit } from "store/slice/initSlice";
import { useTheme } from "apps/form/style/useTheme";
import { CircularLoader } from "components/Loader";

function App(props) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.init);
  const [themeLoader, setThemeLoader] = useState(true);
  const { setStyle } = useTheme();

  useEffect(() => {
    (async function fetchMyInit() {
      try {
        const originalPromiseResult = await dispatch(fetchInit()).unwrap();
        await setStyle(originalPromiseResult.theme);
        // i am saving it in the local storage too, because surveys.js library is not upadating the theme dynamically
        await localStorage.setItem(
          "theme",
          JSON.stringify(originalPromiseResult.theme)
        );
        setThemeLoader(false);
      } catch (rejectedValueOrSerializedError) {
        alert(
          rejectedValueOrSerializedError.message
            ? rejectedValueOrSerializedError.message
            : rejectedValueOrSerializedError
        );
      }
    })();
  }, [dispatch, setStyle]);

  const getRoutes = (allRoutes) => {
    return allRoutes.map((prop, key) => {
      if (prop.name === "Login") {
        return (
          <Route
            path={prop.layout + prop.path}
            element={prop.element}
            key={key}
          />
        );
      }
      if (prop.layout === "form" && prop.name !== "Welcome") {
        console.log(prop);
        return (
          <Route
            path={prop.layout + prop.path}
            element={
              <RequireAuth>
                <FormWelcomeRoute>{prop.element}</FormWelcomeRoute>
              </RequireAuth>
            }
            key={key}
          />
        );
      } else
        return (
          <Route
            path={prop.layout + prop.path}
            element={<RequireAuth>{prop.element}</RequireAuth>}
            key={key}
          />
        );
    });
  };
  if (themeLoader !== false || loading === "pending") {
    console.log(loading);
    return <CircularLoader />;
  } else {
    return (
      <>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <AppRoutes></AppRoutes>
              </RequireAuth>
            }
          />
          {getRoutes(allRoutes)}
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </>
    );
  }
}

export default App;
