import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
export default function RequireAuth({ children }) {
  // const { authed } = useAuth();
  const authed = true;
  const location = useLocation();

  return authed === true ? (
    children
  ) : (
    <Navigate to="/auth/login" replace state={{ path: location.pathname }} />
  );
}
