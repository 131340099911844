import Cookies from "universal-cookie";
const axios = require("axios");

const API_URL = "https://61b854de64e4a10017d18ec7.mockapi.io/mock/api/";
// const API_URL = "https://dev.xane.ai";

const cookies = new Cookies();

const headers = {
  "x-origin": 3,
  "x-version": 1,
  "Content-Type": "application/json",
};

export const requestApi = async (resourcePath, method, params) => {
  let url = API_URL + resourcePath;
  headers["x-auth"] = await cookies.get("x-auth");

  if (resourcePath == "/auths/login") {
    delete headers["x-auth"];
  }

  if (["POST", "PUT", "DELETE"].indexOf(method) > -1 && params) {
    let response = await axios({ url, method, data: params, headers });
    return response.data;
  } else {
    let response = await axios({ url, method, headers });
    return response.data;
  }
};
