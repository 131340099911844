import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { requestApi } from "config/apiHandler";

export const fetchInit = createAsyncThunk(
  "init/fetchInit",
  async (userId, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().init;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await requestApi("/init", "GET");
      if (response[0].response.code === "success") {
        return response[0].response.data;
      } else {
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(response[0].response.message);
      }
    } catch (err) {
      throw err;
    }
  }
);

const initialState = {
  currentRequestId: undefined,
  loading: "idle",
  error: null,
  conversations: [],
  company: {},
  user: {},
  theme: {},
};

export const initSlice = createSlice({
  name: "init",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInit.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchInit.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          const { conversations, company, user, theme } = action.payload;
          state.loading = "idle";
          state.conversations = conversations;
          state.company = company;
          state.user = user;
          state.theme = theme;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchInit.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.error = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.loading = "idle";
        }
      });
  },
});

// Action creators are generated for each case reducer function
// export const { setShowWelcome } = initSlice.actions;

export default initSlice.reducer;
