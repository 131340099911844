import { lazy, Suspense } from "react";
import React from "react";
import ErrorBoundary from "components/ErrorBoundary";
import { CircularLoader } from "components/Loader";

const Login = lazy(() => import("auth/container/Login"));
const FormWelcome = lazy(() => import("apps/form/container/Welcome"));
const FormExit = lazy(() => import("apps/form/container/Exit"));
const FormConversation = lazy(() => import("apps/form/container/Conversation"));
const ChatConversation = lazy(() => import("apps/chat/container/Conversation"));

const LazyLogin = (props) => {
  return (
    <Suspense fallback={<CircularLoader />}>
      <ErrorBoundary>
        <Login {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};
const LazyFormWelcome = (props) => {
  return (
    <Suspense fallback={<CircularLoader />}>
      <ErrorBoundary>
        <FormWelcome {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};
const LazyFormExit = (props) => {
  return (
    <Suspense fallback={<CircularLoader />}>
      <ErrorBoundary>
        <FormExit {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};
const LazyFormConversation = (props) => {
  return (
    <Suspense fallback={<CircularLoader />}>
      <ErrorBoundary>
        <FormConversation {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};
const LazyChatConversation = (props) => {
  return (
    <Suspense fallback={<CircularLoader />}>
      <ErrorBoundary>
        <ChatConversation {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const routes = [
  {
    path: "/login",
    name: "Login",
    element: <LazyLogin />,
    layout: "auth",
  },
  {
    path: "/welcome",
    name: "Welcome",
    element: <LazyFormWelcome />,
    layout: "form",
  },
  {
    path: "/exit",
    name: "Exit",
    element: <LazyFormExit />,
    layout: "form",
  },
  {
    path: "/conversation",
    name: "FormConversation",
    element: <LazyFormConversation />,
    layout: "form",
  },
  {
    path: "/conversation",
    name: "ChatConversation",
    element: <LazyChatConversation />,
    layout: "chat",
  },
];

export default routes;
